import React from 'react';
import Nav from '../components/NavHeader/index';

function NoMatch() {
  return (
    <div>
      <Nav />
      NoMatch</div>
  );
}

export default NoMatch;

import React, { Component } from 'react';
import Nav from '../components/NavHeader/index';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainContainer from "../components/MainContainer/index";
import './styles/Home.css';
import increaseCountExport from "../functions/increase";
import TabOne from "../components/TabOne/index";
import TabTwo from "../components/TabTwo/index";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: "true",
            count: 0,
            activeTab: "TabOne"
        };
    }

    componentDidMount() {
        // const data = { "loading": "true", "count": 0 }
        // localStorage.setItem("save-data-culper", JSON.stringify(data));
        if (localStorage.getItem("save-data-culper") == null) {
            const data = { "loading": "true", "count": 0, "activeTab": "TabOne" }
            localStorage.setItem("save-data-culper", JSON.stringify(data));
        } else {
            const loadData = JSON.parse(localStorage.getItem("save-data-culper"));
            this.setState({
                loading: loadData.loading,
                count: loadData.count
            })
        }
    }

    selectActiveTab = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    renderActiveTab = () => {
        switch (this.state.activeTab) {
            case 'TabTwo':
                return <TabTwo countVal={this.state.count} handleClick={this.increaseCount.bind(this)} />;
            case 'TabOne':
                return <TabOne countVal={this.state.count} handleClick={this.increaseCount.bind(this)} />;
            default:
                return <TabOne countVal={this.state.count} handleClick={this.increaseCount.bind(this)} />;
        }
    }

    increaseCount(event) {
        event.preventDefault();
        this.setState({ count: increaseCountExport(this.state.count) }, function () {
            console.log(this.state);
            console.log(JSON.stringify(this.state));
            localStorage.setItem("save-data-culper", JSON.stringify(this.state));
        });
    };

    render() {
        return (
            <div>
                <Nav />
                <Container fluid>
                    <Row className="row_container">
                        <Col lg={2} className="side_container">
                            <div className="side_tab" onClick={() => this.selectActiveTab("TabOne")}>
                                TabOne
                            </div>
                            <div className="side_tab" onClick={() => this.selectActiveTab("TabTwo")}>
                                TabTwo
                            </div>
                            <div className="side_tab">
                                Tab
                            </div>
                        </Col>
                        <Col className="main_container">
                            {this.renderActiveTab()}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Home;
import React, { Component } from 'react';
import ItemCard from "../ItemCard/index";

function TabOne(props) {
    return (
        <div>
            <ItemCard countVal={props.countVal} handleClick={props.handleClick} />
        </div>
    )
}

export default TabOne;
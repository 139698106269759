import React, { Component } from 'react';
import TabOne from "../TabOne/index";
import TabTwo from "../TabTwo/index";

class MainContainer extends Component {
    render() {
        switch (this.props.activeTab) {
            case 'TabTwo' == this.props.activeTab:
                return (
                    <TabTwo countVal={this.props.count} handleClick={this.props.increaseCount.bind(this)} />
                )
            case 'TabOne' == this.props.activeTab:
                return (
                    <TabOne countVal={this.props.count} handleClick={this.props.increaseCount.bind(this)} />
                )
            default:
                return (
                    <TabOne countVal={this.props.count} handleClick={this.props.increaseCount.bind(this)} />
                )
        }
    }
}

export default MainContainer;

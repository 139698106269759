import React from 'react';
import './style.css';

function ItemCard(props) {
    return (
        <div className="item_card">
            <p>You clicked {props.countVal} times</p>
            <button onClick={props.handleClick} >
                Click
            </button>
        </div>
    )
}

export default ItemCard;
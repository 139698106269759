import React, { Component } from 'react';
import ItemCard from "../ItemCard/index";

function TabTwo(props) {
    return (
        <div>
            <ItemCard countVal={props.countVal} handleClick={props.handleClick} />
            <ItemCard countVal={props.countVal} handleClick={props.handleClick} />
        </div>
    )
}

export default TabTwo;